import { editor } from "monaco-editor";
import { Project, ProjectFile } from "./project";
import { closeModel, setupModel, updateModel } from "../jsInterp";
import { monacoChangeToProtocolChange } from "../protocol";

export namespace LoadedProjectManager {
    let loadedProject: LoadedProject | undefined;
    let loadProjectPromise: Promise<void> | undefined;

    export function ostwModelToWebModel(
        uriStr: string
    ): editor.ITextModel | undefined {
        if (!loadedProject) return;

        // Note: 'reverse' is a hack to make sure that the most recently loaded project
        // is prioritized. The sandbox seems to struggle cleaning up previous saves in the dev
        // environment but is probably fine in production.
        for (const file of loadedProject.project.files) {
            if (file.ostwUri.toString() === uriStr) {
                return file.model;
            }
        }
    }

    export function webModelToOstwModel(model: editor.ITextModel) {
        if (!loadedProject) throw new Error(`No project is loaded.`);

        for (const file of loadedProject.project.files) {
            if (file.model === model) return file.ostwUri;
        }

        throw new Error(`${model.uri} is not loaded.`);
    }

    export async function loadProject(
        project: Project
    ): Promise<LoadedProject> {
        // Wait for existing load.
        if (loadProjectPromise) await loadProjectPromise;

        loadProjectPromise = new Promise(async (resolve) => {
            // If a project is already opened, close it.
            if (loadedProject) {
                await loadedProject.close();
            }

            let needsUpdate = false;
            let projectUpdatedEvent: () => void | undefined;

            function onProjectUpdated() {
                if (!needsUpdate) {
                    needsUpdate = true;
                    projectUpdatedEvent?.();
                }
            }

            async function openFile(file: ProjectFile) {
                console.log(
                    `🗺️ Opening file ${file.monacoUri} (${file.ostwUri})`
                );

                // Watch for file changes.
                file.model.onDidChangeContent((e) => {
                    // Notify server.
                    updateModel(
                        file.ostwUri,
                        e.changes.map((change) =>
                            monacoChangeToProtocolChange(change)
                        )
                    );
                    // Trigger updated event.
                    onProjectUpdated();
                });
                // Notify OSTW server.
                await setupModel(file.model, file.ostwUri);
            }

            // Load new project.
            for (const file of project.files) {
                await openFile(file);
            }

            let opened = true;
            loadedProject = {
                project,
                get name() {
                    return project.getProjectName();
                },
                get needsSave() {
                    return project.linkedSave === undefined && needsUpdate;
                },
                set onProjectUpdated(newEvent: () => void | undefined) {
                    projectUpdatedEvent = newEvent;
                },
                get onProjectUpdated() {
                    return projectUpdatedEvent;
                },
                async close() {
                    // Is project loaded?
                    if (!opened) return;
                    opened = false;

                    // Unload project.
                    for (const file of project.files) {
                        console.log(
                            `🗺️ Closing file ${file.monacoUri} (${file.ostwUri})`
                        );
                        await closeModel(file.ostwUri);
                        file.model.dispose();
                    }
                    loadedProject = undefined;
                },
                defaultFile() {
                    return project.defaultFile();
                },
                addFile(name: string, content: string | undefined) {
                    const newFile = project.addFile(name, content);
                    openFile(newFile);
                    return newFile;
                },
                async delete(fileIndex) {
                    // Notify server of deletion
                    await closeModel(project.files[fileIndex].ostwUri);
                    await project.delete(fileIndex);
                    projectUpdatedEvent?.();
                },
                async rename(index: number, newName: string) {
                    const file = project.files[index];
                    await closeModel(file.ostwUri);
                    await project.rename(index, newName);
                    // Notify server of rename.
                    await setupModel(file.model, file.ostwUri);
                    projectUpdatedEvent?.();
                },
            };
            resolve();
        });
        await loadProjectPromise;
        loadProjectPromise = undefined;
        return loadedProject!;
    }

    export function isLoaded(file: ProjectFile) {
        return loadedProject && loadedProject.project.files.includes(file);
    }
}

export interface LoadedProject {
    readonly project: Project;
    readonly needsSave: boolean;
    readonly name: string;
    onProjectUpdated: () => void | undefined;
    close(): Promise<void>;
    defaultFile(): ProjectFile | undefined;
    addFile(name: string, content: string | undefined): ProjectFile;
    delete(fileIndex: number): Promise<void>;
    rename(index: number, newName: string): Promise<void>;
}
