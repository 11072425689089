import { Uri, editor } from "monaco-editor";
import * as idb from "idb-keyval";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getMonacoUri, getOstwUri } from "./project-util";
import { Save } from "./save";

export class Project {
    // note: make disposable and dispose models uwu
    static defaultProject(): Project {
        const project = new Project();
        project.addFile("untitled.ostw");
        return project;
    }

    static async fromStream(file: File): Promise<Project> {
        const project = new Project();

        const load = await JSZip.loadAsync(file.arrayBuffer());

        for (const file of Object.entries(load.files)) {
            const content = await file[1].async("string");
            project.addFile(file[0], content);
        }

        return project;
    }

    files: ProjectFile[] = [];

    public constructor(public linkedSave?: Save | undefined) {}

    public addFile(
        name: string,
        content: undefined | string = undefined
    ): ProjectFile {
        const monacoUri = getMonacoUri(name);
        const ostwUri = getOstwUri(name);

        const model = editor.createModel(
            content ?? "// " + name,
            "ostw",
            monacoUri
        );

        const newFile: ProjectFile = {
            name,
            model,
            ostwUri,
            monacoUri,
            isDeleted: false,
        };
        this.files.push(newFile);

        if (this.linkedSave) {
            idb.update<string[]>(["files", this.linkedSave?.name], (files) => {
                if (files) {
                    if (!files.includes(name)) {
                        files.push(name);
                    }
                    return files;
                } else {
                    return [name];
                }
            });
        }

        model.onDidChangeContent(async (e) => {
            if (this.linkedSave) {
                await idb.set(
                    ["content", this.linkedSave.name, newFile.name],
                    model.getValue()
                );
            }
        });

        return newFile;
    }

    public defaultFile(): ProjectFile | undefined {
        return this.files.at(0);
    }

    public async delete(index: number) {
        const file = this.files[index];
        this.files.splice(index, 1);
        file.isDeleted = true;
        file.model.dispose();

        if (this.linkedSave) {
            await idb.del(["content", this.linkedSave.name, file.name]);
            await idb.update<string[]>(
                ["files", this.linkedSave.name],
                (files) => {
                    return files ? files.filter((s) => s !== file.name) : [];
                }
            );
        }
    }

    public async rename(index: number, newName: string) {
        const oldName = this.files[index].name;
        this.files[index].name = newName;
        this.files[index].ostwUri = getOstwUri(newName);

        if (this.linkedSave) {
            await idb.del(["content", this.linkedSave.name, oldName]);
            await idb.set(
                ["content", this.linkedSave.name, newName],
                this.files[index].model.getValue()
            );
            await idb.update<string[]>(
                ["files", this.linkedSave.name],
                (files) => {
                    if (files) {
                        const index = files.indexOf(oldName);
                        if (index !== -1) {
                            files[index] = newName;
                        } else {
                            files.push(newName);
                        }
                        return files;
                    } else {
                        return [newName];
                    }
                }
            );
        }
    }

    public getAvailableName(base: string, extension: string): string {
        let name = `${base}.${extension}`;

        let i = 1;
        while (this.isNameTaken(name)) {
            name = `${base}-${i}.${extension}`;
            i++;
        }

        return name;
    }

    public isNameTaken(name: string) {
        return this.files.some((f) => f.name === name);
    }

    public download() {
        const zip = new JSZip();

        this.files.forEach((file) => {
            zip.file(file.name, file.model.getValue());
        });

        zip.generateAsync({ type: "blob" }).then((content) =>
            saveAs(content, "project.zip")
        );
    }

    public getProjectName() {
        return this.linkedSave?.name ?? "untitled";
    }
}

export interface ProjectFile {
    name: string;
    ostwUri: Uri;
    monacoUri: Uri;
    model: editor.ITextModel;
    isDeleted: boolean;
}
