import React from 'react';
import { MonacoEditor3 } from './MonacoEditor3';
import { openOstwFile } from '../jsInterp';
import { DragPanel, DragPanelDirection } from './DragPanel';
import { Project, ProjectFile } from '../project/project';
import { editor } from 'monaco-editor';
import { ModalButton, SandboxIntroduction, SandboxModal } from './SandboxModal';
import { Theme, ThemeSelector } from './ThemeSelector';
import { FontSizeSelector, getDefaultFontSize } from './FontSizeSelector';
import { Notifications } from './Notifications';
import { LoadedProject, LoadedProjectManager } from '../project/loaded-project-manager';
import { Save } from '../project/save';
import { Sidebar } from './Sidebar';
import SaveScreen from './SaveScreen';
import { highlightableButton } from './SandboxButton';

type LoadProject = (loader: () => Promise<Project | undefined>) => void;

type SetProjectFile = (newFile: ProjectFile | undefined) => void;

type SandboxProps = {};

export interface ProjectManager {
    /** The selected theme. */
    theme: Theme | undefined,
    /** Updates the selected theme. */
    setTheme(newTheme: Theme | undefined): void;
    /** The current font size. */
    fontSize: number | undefined,
    /** The currently opened project. */
    activeProject: LoadedProject | undefined,
    /** The currently opened file in the project. */
    activeFile: ProjectFile | undefined,
    /** The modal overlay DOM element. */
    modalOpened: boolean,
    /** Function to open a new file. */
    setProjectFile: SetProjectFile,
    /** Function to load a new project asynchronously. */
    loadProject: LoadProject,
    /** A function to save the current project. */
    saveProject: () => void,
    /** The function to delete a save. */
    deleteSave: (save: Save) => void,
    /** The function to rename a save. */
    renameSave: (save: Save) => void,
    /** Opens a modal with the provided content. */
    setModalContent: (content: React.ReactNode) => void,
    /** Closes the current modal. */
    closeModal: () => void
}

export const Sandbox: React.FC<SandboxProps> = () => {
    // Monaco editor reference
    let mainEditor = React.useRef<editor.IEditor>();
    // The active project state. Call 'changeProject' instead of 'setActiveProject'.
    const [loadingProject, setLoadingProject] = React.useState<Project | undefined>();
    // The active project state. Call 'changeProject' instead of 'setActiveProject'.
    const [activeProject, setActiveProject] = React.useState<LoadedProject | undefined>();
    // The opened file state. Call 'setProjectFileHandler' instead of 'setActiveFile'.
    const [activeFile, setActiveFile] = React.useState<ProjectFile | undefined>();
    // Generic modal.
    const [modalOpened, setModalOpened] = React.useState(false);
    const [modalContent, setModalContentValue] = React.useState<React.ReactNode>();
    // For manually triggering rerenders (use by calling 'triggerUpdate({})')
    const [, triggerUpdate] = React.useState({});
    // Theme and font size
    const [theme, setTheme] = React.useState<Theme>();
    const [fontSize, setFontSize] = React.useState(getDefaultFontSize());
    // Sidebar toggle
    const [sidebarEnabled, setSidebarEnabled] = React.useState(true);
    const [smallMode, setSmallMode] = React.useState(false);

    // Called when the editor is loaded.
    const onEditor = (editor: editor.IEditor | undefined) => {
        // Save a ref to the monaco editor.
        mainEditor.current = editor;
    };

    // Function to update the currently opened file.
    const setProjectFileHandler = (newFile: ProjectFile | undefined) => {
        mainEditor.current?.setModel(newFile?.model ?? null);
        setActiveFile(newFile);
        // Recompile
        if (newFile && LoadedProjectManager.isLoaded(newFile)) {
            openOstwFile(newFile.model);
        }
    };

    /** Opens the project save screen. */
    const openSaveScreen = (onSave?: () => void) => {
        const saveProject = (name: string) => {
            if (activeProject) {
                const save = new Save(name);
                save.add(activeProject.project);
                onSave?.();
            }
        };

        setModalContent(<SaveScreen onFinish={closeModal} addSave={saveProject} />);
    };

    /** Changes the active project. */
    const changeProject = (newProject: Project | undefined) => {
        setLoadingProject(newProject);
        setProjectFileHandler(undefined);
        setActiveProject(undefined);
        // Load the new project.
        if (newProject) {
            LoadedProjectManager.loadProject(newProject).then(loadedProject => {
                setProjectFileHandler(loadedProject.defaultFile());
                setActiveProject(loadedProject);
                loadedProject.onProjectUpdated = () => triggerUpdate({});
            });
        }
    };

    /** Asynchronously loads a new function with the provided loader.
     * Will ensure that current changes are not lost. */
    const loadProjectHandler = (loader: () => Promise<Project | undefined>) => {
        const execLoad = (loader: () => Promise<Project | undefined>) => {
            closeModal();
            loader().then(changeProject);
        };
        // Is the currently opened project unsaved?
        if (activeProject?.needsSave) {
            // Current project will be lost, open modal.
            setModalContent(<div>
                <div>The current project is unsaved. Would you like to save it?</div>
                <ModalButton onClick={() => closeModal()}>Cancel</ModalButton>
                <ModalButton onClick={() => execLoad(loader)}>No</ModalButton>
                <ModalButton onClick={() => openSaveScreen(() => execLoad(loader))}>Yes</ModalButton>
            </div>);
        }
        else {
            execLoad(loader);
        }
    }

    /** Deletes a save. */
    const deleteSaveHandler = (save: Save) => {
        const execDeleteSave = (save: Save) => {
            if (activeProject?.project.linkedSave === save) {
                changeProject(Project.defaultProject());
            }
            save.delete(activeProject?.project);
            closeModal();
        }

        setModalContent(<div>
            <div>Delete project '{save.name}'?</div>
            <ModalButton onClick={closeModal}>No</ModalButton>
            <ModalButton onClick={() => execDeleteSave(save)}>Yes</ModalButton>
        </div>);
    };

    /** Renames a save. */
    const renameSaveHandler = (save: Save) => {
        setModalContent(<SaveScreen onFinish={closeModal} initial={save.name} addSave={(name: string) => {
            save.rename(name).then(() => triggerUpdate({}));
        }} />);
    };

    /** Opens the overlay modal with the provided content. */
    const setModalContent = (content: React.ReactNode) => {
        setModalContentValue(content);
        setModalOpened(true);
    };

    /** Closes the current modal. */
    const closeModal = () => {
        setModalOpened(false);
    };

    // No project loaded, open default.
    if (!loadingProject) {
        changeProject(Project.defaultProject());
    }

    React.useEffect(() => {
        // Event: Warn if leaving without saving
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (activeProject?.needsSave) {
                e.preventDefault();
                return '';
            }
        };

        // Event: Observe window resizes
        const onResize = (e?: UIEvent) => {
            // Overlay sidebar if window width is under 500 pixels.
            setSmallMode(window.innerWidth < 900);
        };
        onResize(); // Initial size check.

        // Listen to events.
        window.addEventListener('beforeunload', onBeforeUnload);
        window.addEventListener('resize', onResize);
        return () => {
            // Cleanup: remove event listeners.
            window.removeEventListener('beforeunload', onBeforeUnload);
            window.removeEventListener('resize', onResize);
        };
    });

    const manager: ProjectManager = {
        theme,
        setTheme: setTheme,
        fontSize,
        activeFile,
        activeProject,
        modalOpened: modalOpened,
        deleteSave: deleteSaveHandler,
        loadProject: loadProjectHandler,
        saveProject: () => openSaveScreen(),
        setProjectFile: setProjectFileHandler,
        renameSave: renameSaveHandler,
        setModalContent,
        closeModal
    };

    const top = topBar(activeProject,
        activeFile,
        theme,
        setTheme,
        fontSize,
        setFontSize,
        () => setSidebarEnabled(!sidebarEnabled),
        sidebarEnabled,
        smallMode);

    return <div className='Ostw-sandbox'>
        {top}
        {sandboxBody(activeFile, onEditor, theme, fontSize, manager, sidebarEnabled, setSidebarEnabled, smallMode)}
        <SandboxIntroduction />
        <SandboxModal isOpen={modalOpened} close={closeModal}>
            {modalContent}
        </SandboxModal>
        <Notifications />
    </div>
};

function topBar(
    activeProject: LoadedProject | undefined,
    activeFile: ProjectFile | undefined,
    theme: Theme | undefined,
    setTheme: (newTheme: Theme) => void,
    fontSize: number,
    setFontSize: (newFontSize: number) => void,
    toggleSidebar: () => void,
    sidebarEnabled: boolean,
    smallMode: boolean
) {
    const fileInfo = <div className='Ostw-sandbox-file-info'>
        <span className='Ostw-sandbox-project-name'>📁{activeProject?.name}</span>
        {' >'}
        <span className='Ostw-sandbox-file-name'>{activeFile?.name}</span>
    </div>;

    const websiteInfo = <div className={smallMode ? 'Ostw-sandbox-top-title-small' : 'Ostw-sandbox-top-title'}>
        <a href='/'>deltin.dev</a>/<span className='Email'>sandbox</span>
    </div>;

    const themeSelector = <ThemeSelector setTheme={setTheme} currentTheme={theme} />;
    const fontSizeSelector = <FontSizeSelector setFontSize={setFontSize} fontSize={fontSize} />;

    const sidebarButton = highlightableButton(sidebarEnabled, "icons/burger-menu.svg", "sidebar", toggleSidebar);
    const externalLinks = <>
        {externalLink('Github', "/icons/github-mark-white.svg", "https://github.com/ItsDeltin/Overwatch-Script-To-Workshop")}
        {externalLink('Discord', "/icons/discord-mark-white.svg", "https://discord.com/channels/570672959799164958/579278548666417162")}
    </>;

    if (smallMode) {
        return <div className='Ostw-sandbox-top-small'>
            <div className='Ostw-sandbox-top-small-row'>
                {websiteInfo}
                <div className='Row-reverse'>
                    {sidebarButton}
                    {/* {themeSelector} */}
                </div>
            </div>
            <div className='Ostw-sandbox-top-small-row'>
                {fileInfo}
                {fontSizeSelector}
                {/* <div className='Row Gap-5'>{externalLinks}</div> */}
            </div>
        </div>
    } else {
        return <div className='Ostw-sandbox-top'>
            {fileInfo}
            {themeSelector}
            {fontSizeSelector}
            {websiteInfo}
            <div className='Row-reverse'>{sidebarButton}{externalLinks}</div>
        </div>
    }
}

function sandboxBody(
    activeFile: ProjectFile | undefined,
    onEditor: (editor: editor.IEditor | undefined) => void,
    theme: Theme | undefined,
    fontSize: number,
    manager: ProjectManager,
    sidebarEnabled: boolean,
    setSidebarEnabled: (isSidebarEnabled: boolean) => void,
    overlaySidebar: boolean) {
    const editor = activeFile ?
        <MonacoEditor3 language='ostw'
            value={activeFile?.model.getValue()}
            model={activeFile?.model}
            setEditor={onEditor}
            theme={theme?.id}
            fontSize={fontSize}
            smallMode={overlaySidebar} /> :
        <div />;

    const sidebar = <Sidebar
        manager={manager}
        overlay={overlaySidebar}
        show={sidebarEnabled}
        setSidebarEnabled={setSidebarEnabled} />;

    if (overlaySidebar) {
        // overlay sidebar
        return <div style={{ width: '100%', height: '100%', position: 'relative' }}>{editor}{sidebar}</div>;
    }
    else {
        // side-by-side sidebar.
        return <DragPanel
            a={editor}
            b={sidebar}
            orientation={DragPanelDirection.Horizontal}
            defaultPos={.7} minPixel={100} maxPixel={300} fullscreen={sidebarEnabled ? 'none' : 'a'} />;
    }
}

export function externalLink(title: string, img: string, link: string) {
    return <a href={link} style={{ textDecoration: 'none', width: '110px', height: '30px' }} target="_blank" rel="noopener noreferrer">
        <div className='Ostw-sandbox-external-link'>
            <img src={img} alt={title + " logo"} />
            <span>{title}</span>
        </div>
    </a>
}
