import ReactModal from "react-modal";
import { createDeleteButton, createEditButton, createSandboxButton } from "./SandboxButton";
import { ModalButton } from "./SandboxModal";
import { decompileWorkshop } from "../jsInterp";
import { ProjectManager } from "./Sandbox";
import React from "react";
import { getConflictingFiles, getNotableText } from "../utils";
import { SavesContainer } from "./SavesDropdown";
import RenameFile from "./RenameFile";

/** The 'Project' tab in the sidebar. */
const ProjectTab: React.FC<{ props: ProjectManager }> = ({ props }) => {
    const [renaming, setRenaming] = React.useState<number>();
    const [addingFile, setAddingFile] = React.useState(false);
    // Manually trigger an update when a file is deleted in the project.
    const [, triggerUpdate] = React.useState({});
    // Modal
    const [decompileModal, setDecompileModal] = React.useState<JSX.Element>();
    const allowDecompileEvent = React.useRef(true);

    // When the 'Add File' button is pressed.
    const addFileHandler = () => {
        setAddingFile(true);
    };

    // Opens the decompile modal.
    const decompileHandler = () => {
        setDecompileModal(<span>Copy the workshop code to your clipboard, then press <span className='Monospace'>ctrl+v</span> to decompile.</span>);
    };

    // This function is executed when a name is chosen
    // for a new file.
    const confirmAddedFileName = (value: string | undefined) => {
        setAddingFile(false);
        // truthy: File name is not undefined or empty.
        if (value && props.activeProject) {
            const newFile = props.activeProject.addFile(value, undefined);
            props.setProjectFile(newFile);
        }
    };

    // Decompile paste event
    React.useEffect(() => {
        if (decompileModal) {
            const func = async (e: Event) => {
                // useState may have this function registered multiple times,
                // 'allowDecompileEvent' ensures it will only happen once.
                if (e instanceof ClipboardEvent && allowDecompileEvent.current && props.activeProject) {
                    allowDecompileEvent.current = false;

                    const text = e.clipboardData?.getData("text");
                    if (text !== undefined) {
                        // Decompile
                        const result = await decompileWorkshop(text);
                        console.log('decompilation result: ' + JSON.stringify(result));

                        if (result.result === 'success') {
                            // Close modal
                            setDecompileModal(undefined);
                            // Add new file
                            const file = props.activeProject.addFile(
                                props.activeProject.project.getAvailableName("decompile", "ostw"),
                                result.code
                            );
                            // Update active file
                            props.setProjectFile(file);
                        } else if (result.result === "incompleted") {
                            // Syntax error
                            setDecompileModal(modalError(
                                `Decompilation failed at line ${result.range.start.line + 1}, character ${result.range.start.character + 1}`,
                                getNotableText(result.original, result.range)));
                        } else {
                            // exception
                            setDecompileModal(modalError('An internal error occured while decompiling:', result.exception));
                        }
                    }
                }
            };
            window.addEventListener("paste", func);
            return () => {
                window.removeEventListener('paste', func, true);
            };
        }
    }); // End decompilation effect

    // No project loaded.
    if (props.activeProject === undefined) {
        return <div />;
    }

    return <div>
        { // Unsaved project, show warning
            props.activeProject.needsSave ? <div className='Sandbox-modal-content-error' style={{ margin: '10px' }}>
                The current project is unsaved.
                <div className='Ostw-sidebar-spacing' />
                {createSandboxButton('Save project as...', props.saveProject)}
            </div> : <div className='Ostw-sidebar-spacing' />}

        <SavesContainer manager={props} />
        <div className='Ostw-sidebar-spacing' />

        <div className='Row'>
            {createSandboxButton('Add File', addFileHandler)}
            {createSandboxButton('Decompile', decompileHandler)}
        </div>
        {addingFile ? <RenameFile
            initial=''
            conflicting={getConflictingFiles(props.activeProject.project)}
            confirm={confirmAddedFileName} key={-1} acceptOnBlur /> : undefined}
        {props.activeProject.project.files.map((document, i) => {
            // on click: open the file.
            const onClick = () => {
                // May fire immediately after project is deleted.
                if (!document.isDeleted) {
                    props.setProjectFile(document);
                }
            };
            // on double click: Rename the file.
            const onDoubleClick = () => {
                setRenaming(i);
            };
            // on delete
            const onDelete = () => {
                const doDelete = () => {
                    props.closeModal();

                    if (!props.activeProject) {
                        return;
                    }

                    // If the file being deleted is opened, switch to a new file.
                    if (props.activeFile === document) {
                        let newFile = i - 1;
                        newFile = newFile < 0 ? 1 : newFile;
                        props.setProjectFile(props.activeProject.project.files.at(newFile));
                    }
                    // External data changed; manually update component.
                    triggerUpdate({});
                    // Delete file from project.
                    props.activeProject.delete(i);
                };

                props.setModalContent(<div>
                    <div>Delete file '{document.name}'?</div>
                    <ModalButton onClick={props.closeModal}>No</ModalButton>
                    <ModalButton onClick={doDelete}>Yes</ModalButton>
                </div>);
            };

            // Is the current document being renamed?
            if (renaming === i) {
                return <RenameFile
                    initial={document.name}
                    conflicting={getConflictingFiles(props.activeProject?.project, document)}
                    confirm={value => {
                        setRenaming(undefined);
                        if (value && value !== "") {
                            props.activeProject?.rename(i, value);
                        }
                    }} key={document.name} acceptOnBlur />;
            } else {
                const className = props.activeFile === document ? 'Project-file-opened' : 'Project-file';
                return <div className='Project-file-container' key={document.name}>
                    <div className={className} onClick={onClick} onDoubleClick={onDoubleClick}>
                        <span>{document.name}</span>
                    </div>
                    <div className='Row'>
                        {createEditButton(onDoubleClick)}
                        {createDeleteButton(onDelete)}
                    </div>
                </div>
            }

        })}
        <ReactModal
            isOpen={decompileModal !== undefined}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onAfterOpen={e => allowDecompileEvent.current = true}
            onRequestClose={e => setDecompileModal(undefined)}
            overlayClassName='Sandbox-modal-overlay'
            className='Sandbox-modal-content'
        >{decompileModal}</ReactModal>
    </div>
};

function modalError(description: JSX.Element | string, details: JSX.Element | string | undefined) {
    return <div className='Sandbox-modal-content-error'>
        {description}<br /><br /><span className='Monospace'>{details}</span>
    </div>;
}

export default ProjectTab;