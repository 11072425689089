import React from "react";

const RenameFile: React.FC<{
    initial?: string,
    conflicting?: string[],
    confirm?: (value: string | undefined) => void,
    contentRef?: React.MutableRefObject<{ text: string, error: string | undefined } | undefined>,
    acceptOnBlur?: boolean,
    emptyError?: boolean
}> = props => {
    const [text, setText] = React.useState(props.initial ?? '');

    // check validity of text
    let error: string | undefined = undefined;

    if (text.includes('/')) {
        error = 'Name contains invalid characters';
    }
    else if (props.conflicting?.includes(text)) {
        error = 'There is already a file with that name';
    }
    else if (!text && props.emptyError) {
        error = 'Text cannot be empty';
    }

    if (props.contentRef) {
        props.contentRef.current = { text, error };
    }

    const apply = () => {
        if (error) {
            props.confirm?.(undefined)
        } else {
            props.confirm?.(text)
        }
    }

    return <div>
        <div className='Project-file-rename'>
            <input type='text'
                value={text}
                onChange={e => {
                    setText(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        apply();
                    }
                    else if (e.key === 'Escape') {
                        props.confirm?.(undefined);
                    }
                }}
                onBlur={e => {
                    if (props.acceptOnBlur) {
                        apply();
                    }
                }}
                autoFocus
            />
        </div>
        {error ? <div className='Rename-notification'>{error}</div> : undefined}
    </div>;
};

export default RenameFile;