import React, { useEffect, useState } from 'react';
import { onWorkshopCode } from '../jsInterp';

export const Notifications: React.FC<{}> = props => {
    const [messages, setMessages] = useState<string[]>([]);

    useEffect(() => {
        const onMessage = (message: string) => {
            setMessages([...messages, message]);
        };

        onWorkshopCode.on('message', onMessage);
        return () => {
            onWorkshopCode.off('message', onMessage);
        }
    }, [messages]);

    return <div className='Notification-list'>
        {messages.map((m, i) => <div className='Notification' key={i}>
            <div>
                <div className='Notification-title'>Notice: an error has occurred</div>
                <div className='Notification-X' onClick={() => {
                    const newArray = [...messages];
                    newArray.splice(i, 1);
                    setMessages(newArray);
                }}>&#x2715;</div>
            </div>
            <div key={i} className='Notification-text'>
                {m}
            </div>
        </div>)}
    </div>
};