import { Project, ProjectFile } from "./project/project";
import { Range } from "./protocol";

export function getConflictingFiles(
    project: Project | undefined,
    active?: ProjectFile
): string[] {
    if (!project) {
        return [];
    }

    const conflicting = project.files.slice(0);
    if (active) {
        conflicting.splice(conflicting.indexOf(active), 1);
    }
    return conflicting.map((c) => c.name);
}

export function getNotableText(text: string, range: Range) {
    const lines = text.split("\n");
    const line = lines.at(range.start.line);
    return line;
}

export function makeClass(...parts: (string | [boolean, string])[]) {
    let result = "";
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] instanceof Array) {
            if (parts[i][0]) {
                result += `${parts[i][1]} `;
            }
        } else {
            result += `${parts[i]} `;
        }
    }
    return result;
}
