import React from "react";
import RenameFile from "./RenameFile";
import { ModalButton } from "./SandboxModal";
import { saves } from "../project/save";

const SaveScreen: React.FC<{
    onFinish: () => void,
    addSave: (name: string) => void,
    initial?: string
}> = props => {
    const content = React.useRef<{ text: string, error: string | undefined }>();

    const save = () => {
        if (content.current?.text && !content.current.error && content.current.text !== props.initial) {
            props.addSave(content.current.text);
        }
        props.onFinish();
    };

    const conflicting = saves.map(s => s.name).filter(s => s !== props.initial);

    return <div>
        Project name:
        <RenameFile contentRef={content} confirm={save} conflicting={conflicting} initial={props.initial} />
        <ModalButton onClick={() => props.onFinish()}>Cancel</ModalButton>
        <ModalButton onClick={save}>Save</ModalButton>
    </div>;
};

export default SaveScreen;