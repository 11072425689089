import React from "react";
import { Examples } from "./Examples";
import ProjectTab from "./ProjectTab";
import { ProjectManager } from "./Sandbox";
import WorkshopOutput from "./WorkshopOutput";
import { makeClass } from "../utils";
import { ThemeSelector } from "./ThemeSelector";

/** Sidebar component. */
export const Sidebar: React.FC<{
    manager: ProjectManager,
    overlay: boolean,
    show: boolean,
    setSidebarEnabled: (isSidebarEnabled: boolean) => void
}> = props => {
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const className = makeClass(props.overlay ? 'Ostw-sidebar-overlay' : 'Ostw-sidebar', [!props.show, 'Ostw-sidebar-hidden']);
    const sidebar = <div className={className} onClick={e => e.stopPropagation()}>
        {tabs(
            activeTab,
            setActiveTab,
            {
                name: 'Project',
                content: <ProjectTab props={props.manager} />
            },
            {
                name: "Compiled Workshop Code",
                content: <WorkshopOutput theme={props.manager.theme?.id} fontSize={props.manager.fontSize} />
            },
            {
                name: "Examples",
                content: <Examples manager={props.manager} setActiveTab={setActiveTab} />
            },
            ...props.overlay ? [{
                name: "Settings",
                content: settings(props.manager)
            }] : [])}
    </div>

    if (props.overlay) {
        const overlayClass = makeClass("Ostw-sidebar-overlay-bg", [!props.show, 'Ostw-sidebar-overlay-bg-hidden']);
        return <div className={overlayClass} onClick={() => props.setSidebarEnabled(false)}>{sidebar}</div>
    } else {
        return sidebar;
    }
};

/** The tabs at the top of the sidebar. */
function tabs(active: number, setActive: (val: number) => void, ...items: {
    name: string,
    content: JSX.Element
}[]) {
    return <div className='Sandbox-tab-menu'>
        <div className='Sandbox-tabs'>
            {items.map((item, i) => <div
                onClick={() => setActive(i)}
                className={active === i ? 'Sandbox-tab-button Sandbox-tab-button-active' : 'Sandbox-tab-button'}
                key={item.name}>{item.name}</div>)}
        </div>
        {items.map((item, i) =>
            <div className='Sidebar-tab-content' style={i === active ? {} : { display: 'none' }}>
                {item.content}
            </div>)}
    </div>
};

function settings(manager: ProjectManager) {
    return <div style={{ margin: '15px' }}>
        Text editor theme: <ThemeSelector currentTheme={manager.theme} setTheme={manager.setTheme} />
    </div>
}
