import { Project } from "./project";
import * as idb from "idb-keyval";

export class Save {
    public constructor(public name: string) {}

    public async load(): Promise<Project> {
        const project = new Project(this);

        const files = await this.getFiles();

        if (files) {
            const contents = await this.getContents(files);

            if (contents.length === files.length) {
                for (let i = 0; i < contents.length; i++) {
                    project.addFile(files[i], contents[i]);
                }
            }
        }

        return project;
    }

    public async add(linkedProject: Project) {
        linkedProject.linkedSave = this;

        // Add to list of saves.
        saves!.push(this);
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                saves.push(this.name);
                return saves;
            } else {
                return [this.name];
            }
        });

        // Update list of files.
        let query: [IDBValidKey, any][] = [
            [["files", this.name], linkedProject.files.map((f) => f.name)],
        ];
        // Update file contents.
        query = query.concat(
            linkedProject.files.map((f) => {
                let x: [IDBValidKey, any] = [
                    ["content", this.name, f.name],
                    f.model.getValue(),
                ];
                return x;
            })
        );

        // Execute file list and file contents query.
        await idb.setMany(query);
    }

    public async delete(linkedProject: Project | undefined): Promise<void> {
        if (linkedProject) {
            linkedProject.linkedSave = undefined;
        }

        // Remove from global saves array.
        const index = saves.indexOf(this);
        if (index !== -1) {
            saves.splice(index, 1);
        }

        // Delete from list of saves.
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                const index = saves.indexOf(this.name);
                if (index !== -1) {
                    saves.splice(index, 1);
                }
                return saves;
            } else {
                return [];
            }
        });

        // Delete files entry.
        const files = await this.getFiles();
        await idb.del(["files", this.name]);

        if (files) {
            await idb.delMany(files.map((f) => ["content", this.name, f]));
        }
    }

    public async rename(newName: string): Promise<void> {
        if (newName === this.name) {
            return;
        }

        // delete 'files/project'
        const deleteQuery: IDBValidKey[] = [["files", this.name]];
        let setQuery: [IDBValidKey, any][] = [];

        const files = await this.getFiles();
        if (files) {
            // Update files entry for new name.
            setQuery.push([["files", newName], files]);
            // Delete 'content/project/*'
            deleteQuery.push(files.map((f) => ["content", this.name, f]));

            // Pair each file with its content.
            const contents = (await this.getContents(files)).map(
                (content, i) => ({ name: files[i], content })
            );

            // Upload contents with new name.
            setQuery = setQuery.concat(
                contents.map<[IDBValidKey, any]>((c) => [
                    ["content", newName, c.name],
                    c.content,
                ])
            );
        }

        // Update save name in array of saves.
        await idb.update<string[]>("saves", (saves) => {
            if (saves) {
                const index = saves.indexOf(this.name);
                if (index !== -1) {
                    // Old name found, replace it.
                    saves[index] = newName;
                } else {
                    // Old name not found, just add new name.
                    saves.push(newName);
                }
                return saves;
            } else {
                // No save list; create new one.
                return [newName];
            }
        });

        // Execute delete queries.
        await idb.delMany(deleteQuery);
        // Execute set queries.
        await idb.setMany(setQuery);

        this.name = newName;
    }

    async getFiles() {
        return await idb.get<string[]>(["files", this.name]);
    }

    async getContents(files: string[]) {
        return await idb.getMany<string>(
            files.map((file) => ["content", this.name, file])
        );
    }
}

export function getSaves() {
    return idb.get<string[]>("saves");
}

export const saves: Save[] = [];

const initialSaves = getSaves().then((initialSaves) => {
    initialSaves?.forEach((save) => {
        saves.push(new Save(save));
    });
});
