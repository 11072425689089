import React from "react";
import { lastWorkshopCode, onWorkshopCode } from "../jsInterp";
import { SandboxCopyButton } from "./SandboxButton";
import { MonacoEditor3 } from "./MonacoEditor3";

const WorkshopOutput: React.FC<{ theme: string | undefined, fontSize: number | undefined }> = props => {
    const [workshopCode, setWorkshopCode] = React.useState<string>(lastWorkshopCode);

    // Listen to workshop code updates.
    React.useEffect(() => {
        onWorkshopCode.on('code', setWorkshopCode);
        return () => {
            onWorkshopCode.off('code', setWorkshopCode);
        }
    }, [setWorkshopCode]);

    // Code copy event
    const onCopy = () => {
        navigator.clipboard.writeText(workshopCode);
    };

    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <SandboxCopyButton title='Copy Code' onClick={onCopy} />
        <MonacoEditor3 language='ow' value={workshopCode} readOnly theme={props.theme} fontSize={props.fontSize} />
    </div>
};

export default WorkshopOutput;