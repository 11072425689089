import React from 'react';

type DragPanelProps = {
    a: JSX.Element,
    b: JSX.Element,
    defaultPos: number,
    minPixel: number,
    maxPixel: number,
    orientation: DragPanelDirection,
    fullscreen?: 'none' | 'a' | 'b'
};

export enum DragPanelDirection {
    Horizontal,
    Vertical
}

export const DragPanel: React.FC<DragPanelProps> = ({ a, b, defaultPos, minPixel, maxPixel, orientation, fullscreen }) => {
    const [panelPos, setPanelPos] = React.useState<number>(defaultPos);
    const [isDragging, setIsDragging] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement>(null);

    const direction = orientation;
    const classNames = {
        container: ['Drag-panel-container-horizontal', 'Drag-panel-container-vertical'][direction],
        line: ['Drag-panel-line-horizontal', 'Drag-panel-line-vertical'][direction],
        hitbox: ['Drag-panel-line-hitbox-horizontal', 'Drag-panel-line-hitbox-vertical'][direction],
        visual: ['Drag-panel-line-visual-horizontal', 'Drag-panel-line-visual-vertical'][direction],
        active: [' Drag-panel-line-visual-horizontal-active', ' Drag-panel-line-visual-vertical-active'][direction]
    };

    const handleMove = (e: React.MouseEvent) => {
        if (!isDragging || ref.current === null) {
            return;
        }
        const cursorPos = [e.pageX - ref.current.offsetLeft, e.pageY - ref.current.offsetTop][direction];
        const width = [ref.current.offsetWidth, ref.current.offsetHeight][direction];

        const min = minPixel / width;
        const max = (width - maxPixel) / width;
        const requested = cursorPos / width;

        setPanelPos(Math.max(min, Math.min(requested, max)));
    };

    const handlePointerDown = (e: React.MouseEvent) => {
        e.preventDefault();
        if (!fullscreen || fullscreen === 'none')
            setIsDragging(true);
    };

    const handlePointerUp = () => {
        setIsDragging(false);
    }

    React.useEffect(() => {
        window.addEventListener('pointerup', handlePointerUp);
        return () => window.removeEventListener('pointerup', handlePointerUp);
    }, []);

    let containerClassName = classNames.container;
    if (isDragging)
        containerClassName += ' Dragging-panel';

    function sizeStyle(value: any): React.CSSProperties {
        return {
            width: [value, undefined][direction],
            height: [undefined, value][direction],
            position: 'relative'
        };
    }

    const usePanelPos = fullscreen === 'a' ? 1 : fullscreen === 'b' ? 0 : panelPos;
    const aSize = usePanelPos * 100 + "%";
    const bSize = (1 - usePanelPos) * 100 + "%";

    const sizeSectionClassName = !isDragging ? 'Transition-02s' : undefined;
    return <div ref={ref} className={containerClassName}
        onPointerMove={handleMove}
        onPointerUp={handlePointerUp}>
        {/* A */}
        <div style={sizeStyle(aSize)} className={sizeSectionClassName}>
            <div style={{ position: 'absolute', inset: 0 }}>{a}</div>
        </div>
        {/* split line */}
        <div className={classNames.line}>
            <div className={classNames.hitbox} onPointerDown={handlePointerDown}>
                <div className={classNames.visual + (isDragging ? classNames.active : '')} />
            </div>
        </div>
        {/* B */}
        <div style={sizeStyle(bSize)} className={sizeSectionClassName}>
            <div style={{ position: 'absolute', inset: 0 }}>{b}</div>
        </div>
    </div>
};