import { editor, Uri } from "monaco-editor";

export function getMonacoUri(name: string): Uri {
    const models = editor.getModels();
    let monacoUri = getOstwUri(name);

    let i = 1;
    // eslint-disable-next-line
    while (models.some((m) => m.uri.toString() === monacoUri.toString())) {
        monacoUri = Uri.parse(`${i}-${name}`);
        i++;
    }

    return monacoUri;
}

export function getOstwUri(fileName: string) {
    return Uri.parse(fileName);
}
