import React from 'react';
import { Project } from '../project/project';
import { createDeleteButton, createEditButton, createSandboxButton } from './SandboxButton';
import { ProjectManager } from './Sandbox';
import { saves } from '../project/save';

export const SavesContainer: React.FC<{ manager: ProjectManager }> = ({ manager }) => {
    const [dropdownActive, setDropdownActive] = React.useState(false);
    let dropdownRef = React.useRef<HTMLDivElement>(null);
    // Upload
    const inputFile = React.useRef<HTMLInputElement | null>(null);

    // Close the dropdown window then an area outside the screen is clicked.
    React.useEffect(() => {
        const onOutsideClick = (e: MouseEvent) => {
            if (// If dropdown is opened...
                dropdownRef.current && e.target instanceof Node
                // ...and modal is closed...
                && !manager.modalOpened
                // ...and mouse event does not include dropdown ...
                && !dropdownRef.current.contains(e.target)
            ) {
                // ...then close the dropdown.
                setDropdownActive(false);
            }
        };

        document.addEventListener('mousedown', onOutsideClick);

        return () => {
            document.removeEventListener('mousedown', onOutsideClick);
        };
    }, [manager.modalOpened]);

    /** On dropdown button clicked. */
    const onClick = () => {
        setDropdownActive(!dropdownActive);
    };

    /** Loads an uploaded zip file. */
    const handleProjectFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        manager.loadProject(async () => {
            const file = e.target.files?.item(0);
            if (file) {
                return await Project.fromStream(file);
            }
        });
    };

    // Button handler for downloading project.
    const downloadProjectHandler = () => {
        manager.activeProject?.project.download();
    };

    /** Opens the file dialog for a project.zip file.  */
    const loadFromZipHandler = () => {
        inputFile.current?.click();
    };

    return <div style={{ position: 'relative', userSelect: 'none', width: '100%' }} ref={dropdownRef}>
        <div className={'Ostw-sidebar-title' + (dropdownActive ? ' Ostw-sidebar-opened' : '')} onClick={onClick}>
            {manager.activeProject?.name}
            <div className={'Ostw-sidebar-title-arrow' + (dropdownActive ? ' Ostw-sidebar-title-arrow-opened' : '')}>
                &#9660;
            </div>
        </div>
        {dropdownActive ? <div className='Sandbox-saves-dropdown'>
            {createSandboxButton('New project', () => { manager.loadProject(async () => Project.defaultProject()) })}
            <div className='Row'>
                {createSandboxButton('Download project', downloadProjectHandler)}
                {createSandboxButton('Load from zip', loadFromZipHandler)}
            </div>

            {saves.length === 0 ? <div>
                No projects are saved.
            </div> : ['Projects:', saves.map(save => {
                /** When the save is clicked, load it. */
                const saveClickHandler = () => manager.loadProject(() => {
                    setDropdownActive(false);
                    return save.load();
                });

                /** Edit button */
                const onEdit = () => manager.renameSave(save);

                /** Delete button */
                const onDelete = () => manager.deleteSave(save);

                const isOpened = manager.activeProject?.project.linkedSave === save;

                return <div key={save.name} className='Sandbox-project-container'>
                    <div className={'Sandbox-project' + (isOpened ? ' Sandbox-project-opened' : '')}
                        onClick={saveClickHandler}
                    >
                        {save.name} {isOpened ? '(opened)' : undefined}
                    </div>
                    {createEditButton(onEdit)}
                    {createDeleteButton(onDelete)}
                </div>;
            })]}
        </div> : undefined}
        <input type='file' ref={inputFile} style={{ display: 'none' }} accept='.zip' onChange={handleProjectFileSelected} />
    </div>
};
