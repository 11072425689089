import { editor, Uri } from "monaco-editor";
import { languages } from "monaco-editor";
import {
    ScriptDiagnostics,
    diagnosticToMarker,
    CompletionItem as ProtocolCompletionItem,
    toMonacoCompletionItem,
    SignatureHelp,
    toMonacoSignatureHelp,
    toProtocolSignatureContext,
    Hover,
    toMonacoHover,
    DecompileResult,
    ChangeEvent,
} from "./protocol";
import { ostwLanguageConfiguration } from "./languageConfiguration";
import { loadWASM } from "onigasm";
import { Registry } from "monaco-textmate";
import { monaco } from "react-monaco-editor";
import { wireTmGrammars } from "monaco-editor-textmate";
import EventEmitter from "events";
import {
    WorkerNotification,
    ServerRequest,
    SendServerRequest,
    ServerRequestValueMap,
} from "./worker/workerTypes";
import { LoadedProjectManager } from "./project/loaded-project-manager";

export const onWorkshopCode: EventEmitter = new EventEmitter();
export let lastWorkshopCode: string = "// compiling workshop...";

let setupLanguagePromise: Promise<void> | undefined;
let resolveServer: (value: OstwServer) => void;
let server: Promise<OstwServer> = new Promise(
    (resolve) => (resolveServer = resolve)
);

class OstwServer {
    serverWorker: Worker;
    currentMessageId = 0;
    pendingRequests: {
        id: number;
        resolve: (value: any) => void;
    }[] = [];

    public constructor() {
        // serverWorker = new Worker(/* webpackIgnore: true */'./ostw/AppBundle/main.js', { type: 'module' });
        this.serverWorker = new Worker(
            /* webpackChunkName: "ostw-server-worker" */ new URL(
                "./worker/serverWorker.ts",
                import.meta.url
            ),
            { type: "module" }
        );
        this.serverWorker.addEventListener("message", ({ data }) => {
            const notification: WorkerNotification = data;

            switch (notification.type) {
                case "notification_diagnostics":
                    gotDiagnostics(notification.diagnostics);
                    break;

                case "notification_code":
                    lastWorkshopCode = notification.code;
                    onWorkshopCode.emit("code", notification.code);
                    break;

                case "notification_misc":
                    lastWorkshopCode = notification.message;
                    onWorkshopCode.emit("message", notification.message);
                    break;

                case "response":
                    const pendingIndex = this.pendingRequests.findIndex(
                        (pending) => pending.id === notification.id
                    );
                    if (pendingIndex !== -1) {
                        this.pendingRequests[pendingIndex].resolve(
                            notification.data
                        );
                        this.pendingRequests.splice(pendingIndex, 1);
                    }
                    break;
            }
        });
    }

    public async sendRequest<Request extends ServerRequest>(
        request: Request
    ): Promise<ServerRequestValueMap[Request["type"]]> {
        // Create unique id.
        const id = this.currentMessageId++;

        // Send request.
        const sendRequest: SendServerRequest = {
            id,
            request,
        };
        this.serverWorker.postMessage(sendRequest);

        // Wait for response.
        const promise = new Promise<any>((resolve) => {
            this.pendingRequests.push({ id, resolve });
        });
        return await promise;
    }
}

function gotDiagnostics(diagnostics: string) {
    editor.removeAllMarkers("owner");
    const scriptDiagnostics: ScriptDiagnostics[] = JSON.parse(diagnostics);

    scriptDiagnostics.forEach((e) => {
        // Find model attached to URI.
        const publishToModel = LoadedProjectManager.ostwModelToWebModel(e.uri);

        // Ensure that the model exists.
        if (publishToModel) {
            const diagnostics = e.diagnostics.map((d) => diagnosticToMarker(d));
            // Apply diagnostics to model.
            editor.setModelMarkers(publishToModel, "owner", diagnostics);
        } else {
            console.log("Received diagnostics for nonexistant model: " + e.uri);
        }
    });
}

export async function setupLanguage() {
    if (setupLanguagePromise === undefined) {
        setupLanguagePromise = new Promise(async (resolve) => {
            const server = new OstwServer();
            resolveServer(server);
            await setupLanguageInner(server);
            resolve();
        });
    }
    await setupLanguagePromise;
}

async function setupLanguageInner(server: OstwServer) {
    const langName = "ostw";
    languages.register({ id: langName, extensions: ["ostw", "del"] });
    languages.setLanguageConfiguration(langName, ostwLanguageConfiguration);
    // languages.setMonarchTokensProvider(langName, ostwLanguage);

    // Add providers
    // Completion
    languages.registerCompletionItemProvider(langName, {
        async provideCompletionItems(model, position) {
            const items: ProtocolCompletionItem[] = await server.sendRequest({
                type: "completion",
                uri: toOstwUri(model),
                position,
            });

            return {
                suggestions: items.map((i) =>
                    toMonacoCompletionItem(
                        i,
                        position.lineNumber,
                        position.column
                    )
                ),
            };
        },
        triggerCharacters: ["."],
    });
    // Signature Help
    languages.registerSignatureHelpProvider(langName, {
        async provideSignatureHelp(model, position, token, context) {
            const signatureHelpJson: SignatureHelp = await server.sendRequest({
                type: "signature",
                uri: toOstwUri(model),
                position,
                context: toProtocolSignatureContext(context),
            });

            return {
                value: toMonacoSignatureHelp(signatureHelpJson),
                dispose() {},
            };
        },
        signatureHelpTriggerCharacters: ["("],
        signatureHelpRetriggerCharacters: [","],
    });
    // Hover
    languages.registerHoverProvider(langName, {
        async provideHover(model, position, token) {
            const hover: Hover = await server.sendRequest({
                type: "hover",
                uri: toOstwUri(model),
                position: position,
            });
            return toMonacoHover(hover);
        },
    });

    const legend = await server.sendRequest<any>({ type: "tokenData" });

    // Semantic Tokens
    languages.registerDocumentSemanticTokensProvider(langName, {
        getLegend() {
            return legend;
        },
        async provideDocumentSemanticTokens(model, lastResultId, token) {
            return await server.sendRequest<any>({ type: "semanticTokens" });
        },
        releaseDocumentSemanticTokens(resultId) {},
    });
    await loadWASM("/onigasm.wasm");

    // Workshop language
    const workshopName = "ow";
    languages.register({ id: workshopName, extensions: ["ow", "workshop"] });

    // Wire Tm language
    await wireTmGrammars(monaco, ostwRegistry.registry, ostwRegistry.grammar);
}

export async function setupModel(model: editor.IModel, ostwUri: Uri) {
    // In case model is disposed in between call and ostw ready.
    if (model.isDisposed()) {
        return;
    }

    await (
        await server
    ).sendRequest({
        type: "addModel",
        uri: ostwUri.toString(),
        content: model.getValue(),
    });
}

export async function closeModel(ostwUri: Uri) {
    (await server).sendRequest({
        type: "closeModel",
        uri: ostwUri.toString(),
    });
    console.log(`🗺️ file closed!`);
}

export async function updateModel(ostwUri: Uri, changes: ChangeEvent[]) {
    await (
        await server
    ).sendRequest({
        type: "updateModel",
        uri: ostwUri.toString(),
        changes: changes,
    });
}

function createLanguageRegistry(
    tmLanguage: string,
    lang: string,
    source: string
): { registry: Registry; grammar: Map<string, string> } {
    const registry = new Registry({
        getGrammarDefinition: async (scopeName, dependentScope) => {
            let name = "/ostw.tmLanguage.json";
            switch (scopeName) {
                case "source.ow":
                    name = "/ow.tmLanguage.json";
                    break;
            }

            return {
                format: "json",
                content: await (await fetch(name)).text(),
            };
        },
    });

    const grammar = new Map();
    grammar.set("ostw", "source.del");
    grammar.set("ow", "source.ow");

    return { registry, grammar };
}

const ostwRegistry = createLanguageRegistry(
    "/ostw.tmLanguage.json",
    "ostw",
    "source.del"
);

export async function decompileWorkshop(
    code: string
): Promise<DecompileResult> {
    return await (
        await server
    ).sendRequest({
        type: "decompile",
        text: code,
    });
}

export async function openOstwFile(model: editor.ITextModel) {
    await (await server).sendRequest({ type: "open", uri: toOstwUri(model) });
}

function toOstwUri(model: editor.ITextModel): string {
    return LoadedProjectManager.webModelToOstwModel(model).toString();
}
